<template>
  <b-row class="px-1">
    <b-col cols="5">
      <div class="d-flex justify-content-between">
        <h4>Task</h4>

        <!-- <b-button
          variant="transparent"
          size="sm"
          v-b-tooltip.hover.lefttop="'Court Info Tasks'"
          @click="openDsCourtInfoTasks"
        >
          <ScaleIcon />
        </b-button> -->
      </div>

      <b-table
        sticky-header="50vh"
        no-border-collapse
        :items="taskItems"
        responsive
        small
        :fields="fields"
      >
        <template #cell(date)="data">
          <span>{{ data.value | myGlobalDay }}</span>
        </template>
        <template #cell(actions)="data">
          <b-row>
            <b-col>
              <feather-icon
                v-if="!isResponsibleAccount"
                icon="CheckIcon"
                class="text-success cursor-pointer"
                size="20"
                @click="checkTask(data.item.id)"
              />
            </b-col>
            <b-col>
              <feather-icon
                v-if="
                  (data.item.creator_id == currentUser.user_id ||
                    currentUser.role_id == 2 ||
                    currentUser.role_id == 1) &&
                  !onlyRead &&
                  !isResponsibleAccount
                "
                icon="Edit3Icon"
                class="text-warning cursor-pointer"
                size="20"
                @click="editTask(data.item)"
              />
            </b-col>
            <b-col>
              <feather-icon
                icon="EyeIcon"
                class="text-primary cursor-pointer"
                size="20"
                @click="showTask(data.item)"
              />
            </b-col>
            <b-col>
              <feather-icon
                v-if="
                  (data.item.creator_id == currentUser.user_id ||
                    currentUser.role_id == 2 ||
                    currentUser.role_id == 1) &&
                  !onlyRead &&
                  !isResponsibleAccount
                "
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                size="20"
                @click="deleteTask(data.item.id)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-row class="justify-content-between px-1 mt-1">
        <b-button
          class="mb-1"
          variant="primary"
          @click="openiListCompletedTasksModal"
        >
          <feather-icon icon="EyeIcon" /> View completed tasks
        </b-button>
        <b-button class="mb-1" variant="primary" @click="openModalAddTask">
          <feather-icon icon="PlusIcon" /> Add task
        </b-button>
      </b-row>
    </b-col>
    <b-col cols="7">
      <b-row class="justify-content-between pl-4 pr-1">
        <b-form-group label="Notes">
          <b-form-select
            v-model="noteSelected"
            @change="changeYear"
            :options="years"
          />
        </b-form-group>
        <b-button class="my-2" variant="primary" @click="openAddNotesModal">
          <feather-icon icon="PlusIcon" /> ADD NOTE
        </b-button>
      </b-row>
      <b-row class="w-100 m-1">
        <b-col cols="2" v-for="(month, i) in months" :key="i">
          <b-card
            class="border-light rounded-lg"
            border-variant="info"
            :header="month"
            header-class=" py-1"
            header-bg-variant="info"
            header-text-variant="white"
            body-class="py-1"
            align="center"
          >
            <b-card-text>
              <h4
                class="cursor-pointer text-info m-0 p-0"
                @click="openModalAllNotesByMonth(i)"
              >
                {{ noteItems[i] }}
              </h4>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="pl-3 mt-1 justify-content-start">
        <b-button
          :disabled="isResponsibleAccount"
          :variant="updateNewVersion == 0 ? 'outline-info' : 'warning'"
          @click="openModalNotesAll"
        >
          <feather-icon icon="Edit2Icon" /> First Note
          <span v-if="updateNewVersion == 0"> [ Pending update ] </span>
        </b-button>
        <b-button
          v-if="idNotes != null"
          @click="showSummaryModal = true"
          variant="info"
          class="ml-1"
        >
          <feather-icon icon="PhoneIcon" />
          SALE CALL
        </b-button>
      </b-row>
    </b-col>
    <add-notes-modal
      v-if="isActiveAddNotesModal"
      :name-client="client"
      :editing="false"
      @close="closeAddNotesModal"
      @reload="reloadNotes"
    />
    <modal-notes-paragon
      v-if="isActiveModalNotesAll"
      :note-info="noteInfo"
      @hide="closeModalNotesAll"
    />
    <ModalNotesFirst
      v-if="isActiveModalBusinessNotes"
      :note-info="noteInfo"
      :show-new-version="showNewVersion"
      :sales-completed="salesCompleted"
      @hide="closeModalBusinessNotesAll"
      @refreshNotes="reloadNotes"
    />
    <!-- <modal-notes-all
      v-if="isActiveModalNotesAll"
      :note-info="noteInfo"
      :is-from-task-notes="true"
      @hide="closeModalNotesAll"
    /> -->
    <list-all-notes-modal
      v-if="isActiveModalAllNotes"
      :month="notesData.month"
      :year="notesData.year"
      :nameClient="client"
      @close="closeModalAllNotesByMonth"
    />
    <list-completed-tasks-modal
      v-if="isActiveListCompletedTasksModal"
      :name-client="clientName"
      @close="closeListCompletedTasksModal"
    />
    <add-task-modal
      v-if="isActiveModalAddTask"
      :task-data="taskToSend"
      :to-edit="toEdit"
      :show-mood="showMood"
      :aid="clientData.advisor.id"
      :aname="clientData.advisor.name"
      :sid="clientData.supervisor.id"
      :sname="clientData.supervisor.name"
      :name-client="clientName"
      @close="closeModalAddTask"
      @reload="reloadTasks"
    />
    <CallSummaryModal
      v-if="showSummaryModal"
      :idNotes="idNotes"
      :onlyView="true"
      @closeModal="showSummaryModal = false"
    />
    <!-- <DSCourtInfoTasks
      v-if="showDsCourtInfoTasks"
      :id="clientData.accountId"
      @close="showDsCourtInfoTasks = false"
    /> -->
  </b-row>
</template>
<script>
//vuex
import { mapActions, mapGetters } from "vuex";
//modals
import ModalAllNotes from "../modals/ModalAllNotes.vue";
import AddNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddNotesModal.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import AddTaskModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddTaskModal.vue";
import ListCompletedTasksModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListCompletedTasksModal.vue";
// import DSCourtInfoTasks from "@/views/debt-solution/views/court-info/components/modals/DSCourtInfoTasks.vue";
// import ScaleIcon from "@/views/ui/icons/ScaleIcon.vue";
import CallSummaryModal from "@/views/crm/views/sales-made/components/modals/CallSummaryModal.vue";

export default {
  components: {
    AddNotesModal,
    ModalAllNotes,
    ModalNotesAll,
    ListAllNotesModal,
    ModalNotesParagon,
    ModalNotesFirst,
    ListCompletedTasksModal,
    AddTaskModal,
    CallSummaryModal,
    // DSCourtInfoTasks,
    // ScaleIcon,
  },
  data() {
    return {
      idNotes: null,
      isActiveModalAllNotes: false,
      isActiveAddTaskModal: false,
      isActiveAddNotesModal: false,
      isActiveModalNotesAll: false,
      isActiveListAllNotesModal: false,
      isActiveModalBusinessNotes: false,
      noteSelected: new Date().getFullYear(),
      notesData: { month: "", year: "" },
      fields: [
        { key: "subject", label: "Subject" },
        { key: "date", label: "Date / Hour" },
        { key: "actions", label: "Actions", thClass: "text-center" },
      ],
      isActiveListCompletedTasksModal: false,
      isActiveModalAddTask: false,
      showMood: false,
      // noteInfo:{},
      // showDsCourtInfoTasks: false,
      updateNewVersion: false,
      showSummaryModal: false,
    };
  },
  async created() {
    await this.getNoteIds();
  },
  methods: {
    async getNoteIds() {
      const params = {
        ca_id: this.$route.params.idClient,
      };
      try {
        const { data } = await ClientsOptionsServices.getNoteIds(params);
        this.idNotes = data.id_notes;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getCreatesSale() {
      try {
        this.addPreloader();
        const params = {
          sale_id: this.noteInfo.saleId,
        };
        const { data } = await ClientsOptionsServices.getCreatesSale(params);
        this.updateNewVersion = data.is_new;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openAddTaskModal() {
      this.isActiveAddTaskModal = true;
    },
    closeAddTaskModal() {
      this.isActiveAddTaskModal = false;
    },
    openListAllNotesModal() {
      this.isActiveListAllNotesModal = true;
    },
    closeListAllNotesModal() {
      this.isActiveListAllNotesModal = false;
    },
    openModalNotesAll() {
      if (this.moduleId == 3 && this.noteInfo.module == 3) {
        this.isActiveModalBusinessNotes = true;
        this.showNewVersion = true;
        this.salesCompleted = true;
        return;
      }
      this.isActiveModalNotesAll = true;
    },
    closeModalNotesAll() {
      this.isActiveModalNotesAll = false;
    },
    closeModalBusinessNotesAll() {
      this.isActiveModalBusinessNotes = false;
    },
    openAddNotesModal() {
      this.isActiveAddNotesModal = true;
    },
    closeAddNotesModal() {
      this.isActiveAddNotesModal = false;
    },
    openModalAllNotesByMonth(index) {
      this.notesData.year = `${this.noteSelected}`;
      this.notesData.month = index + 1;
      this.isActiveModalAllNotes = true;
    },
    closeModalAllNotesByMonth() {
      this.isActiveModalAllNotes = false;
    },
    changeYear() {
      this.notesData.year = this.noteSelected;
      const params = {
        id: this.accountId,
        year: this.noteSelected,
        iduser: this.currentUser.user_id,
      };
      this.getNotesData(params);
    },
    reloadNotes() {
      const params = {
        id: this.accountId,
        year: this.noteSelected,
        iduser: this.currentUser.user_id,
      };
      this.getNotesData(params);
      this.getCreatesSale();
    },
    async deleteTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.reloadTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    openiListCompletedTasksModal() {
      this.isActiveListCompletedTasksModal = true;
    },
    openModalAddTask() {
      this.taskToSend = {};
      this.toEdit = true;
      this.isActiveModalAddTask = true;
    },
    showTask(item) {
      let data = item;
      data.date1 = item.date.substr(0, 10);
      data.hour = item.date.substr(11);
      this.isActiveModalAddTask = true;
      this.showMood = true;
      this.taskToSend = data;
    },
    editTask(item) {
      let data = item;
      data.date1 = item.date.substr(0, 10);
      data.hour = item.date.substr(11);
      this.taskToSend = data;
      this.toEdit = true;
      this.isActiveModalAddTask = true;
    },
    async reloadTasks() {
      await this.getTaskData(this.taskParams);
    },
    async checkTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.checkTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.reloadTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    closeListCompletedTasksModal() {
      this.isActiveListCompletedTasksModal = false;
    },
    closeModalAddTask() {
      this.taskToSend = {};
      this.showMood = false;
      this.isActiveModalAddTask = false;
    },
    ...mapActions({
      getNotesData: "ParagonClientDashboard/getNotesData",
      getTaskData: "ParagonClientDashboard/getTaskData",
    }),
  },
  computed: {
    onlyRead() {
      return this.moduleId === 12;
    },
    taskParams() {
      return {
        id: this.accountId,
        user_id: this.currentUser.user_id,
      };
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      accountId: "ParagonClientDashboard/getAccountId",
      months: "ParagonClientDashboard/getMonths",
      years: "ParagonClientDashboard/getYears",
      taskItems: "ParagonClientDashboard/getTaskData",
      taskPaginate: "ParagonClientDashboard/getTaskPaginate",
      noteItems: "ParagonClientDashboard/getNotesData",
      client: "ParagonClientDashboard/getClient",
      noteInfo: "ParagonClientDashboard/getNoteInfo",
      clientName: "ParagonClientDashboard/getClient",
      clientData: "ParagonClientDashboard/getPersonalInputs",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  watch: {
    "noteInfo.saleId": {
      handler(newSaleId) {
        if (newSaleId !== undefined) {
          this.getCreatesSale();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
